import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useAppContext } from '../../datastore/AppContext';
import { StyledHeadingIcon, StyledTitle } from '../../common/styles/global';
import {
    CDS_MAJORSOURCE_UNSUB as majorSource,
    CDS_MINORSOURCE_UNDEF as minorSource,
} from '../../common/Config';
import checkboxIcon from '../../assets/images/check-circle.svg';
import loadingIcon from '../../assets/images/loader.gif';

/**
 * Maps an array of user preference objects to include only the preferenceId and an
 * optinValue of N - unsubscribed
 * @param {Array} preferences An array of preference objects retrieved from app context
 */
export const unsubscribePreferences = (preferences = []) => preferences.map(preference => ({
    preferenceId: preference.preferenceId,
    optinValue: 'N',
}));

/**
 * Loader component. Handles logic to unsubscribe a user from all preferences and
 * displays the loader animation until the unsubscribe request has completed.
 *
 * @param {function} setLoading     Function that sets the loading state.
 * @param {function} setSubscribed  Function that sets the subscribed state.
 */
export const Loader = ({ setLoading, setSubscribed }) => {
    const { user, updatePreferences, setPreferences } = useAppContext();

    useEffect(() => {
        // Make a copy of all user preferences set to unsubscribed.
        const preferences = unsubscribePreferences(user.preferences);

        setPreferences(preferences);

        /**
         * Makes an async request via updatePreferences to unsubscribe a user from all preferences.
         */
        const update = () => {
            const response = updatePreferences({
                ...user,
                preferences,
                majorSource,
                minorSource,
            });

            if (Object.prototype.isPrototypeOf.call(Error.prototype, response)) {
                // updatePreferences has received and returned an error
                setLoading(false);
            } else {
                // updatePreferences has succeeded
                setSubscribed(false);
                setLoading(false);
            }
        };

        update();
    });

    return <StyledHeadingIcon src={loadingIcon} />;
};

Loader.propTypes = {
    setLoading: PropTypes.func.isRequired,
    setSubscribed: PropTypes.func.isRequired,
};

/**
 * Component to redirect to the error page.
 */
export const ErrorRedirect = () => <Redirect to="/error" />;

/**
 * Component that displays the unsubscribe success page.
 */
export const Unsubscribed = () => (
    <>
        <StyledHeadingIcon src={checkboxIcon} alt="unsubscribe checkbox" />
        <StyledTitle>
            <FormattedMessage
              id="page.unsubscribe.success.txt"
              default="You have successfully unsubscribed from all Hearst magazines email subscriptions."
            />
        </StyledTitle>
    </>
);

/**
 * Wrapper component for the various states of the page. Default export.
 */
const UnsubscribeAll = () => {
    const { user: { preferences } } = useAppContext();
    const [subscribed, setSubscribed] = useState(!!preferences.length);
    const [loading, setLoading] = useState(true);

    // there are no subscriptons, render unsubscribe success
    if (!subscribed) {
        return <Unsubscribed />;
    }

    // subscriptions exist and the request to unsubscribe must be made, render Loader
    if (subscribed && loading) {
        return <Loader setLoading={setLoading} setSubscribed={setSubscribed} />;
    }

    // subscriptions exist and the unsub request completed. something went wrong, redirect to error.
    return <ErrorRedirect />;
};

export default UnsubscribeAll;
