import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StyledInputContainer, StyledInputLabel, StyledInput } from './Login.styles';

/**
 * The email entry box on the login page
 * @param {object} intl The `react-intl` library HOC that gives component ability for the translation
 * @param {Function} handleChange The function to be called whenever the email is changed
 */
const Login = ({ intl, handleChange, value, hasError }) => {
    const [focused, setFocused] = useState(false);
    const onInputFocus = () => {
        setFocused(true);
    };
    const onInputBlur = () => {
        setFocused(false);
    };

    return (
        <StyledInputContainer hasError={hasError} focused={focused}>
            <StyledInputLabel
              hasError={hasError}
              value={value}
              focused={focused}
              htmlFor="email-login-input"
            >
                {intl.formatMessage({ id: 'page.index.sign-in.label' })}
            </StyledInputLabel>
            <StyledInput
              id="email-login-input"
              type="text"
              onBlur={onInputBlur}
              onFocus={onInputFocus}
              value={value}
              onChange={handleChange}
            />
        </StyledInputContainer>
    );
};

Login.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    hasError: PropTypes.bool.isRequired,
};

export default injectIntl(Login);
