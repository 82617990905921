import { useState } from 'react';
import axios from 'axios';

import { API_ROOT, USER_MAX_AGE } from './Config';

/**
 * Factory for utility function that will call `setUserid` and `setPreferences` with
 * provided arguments.
 * @param {function} setUserId       State setter from `useState`
 * @param {function} setPreferences  State setter from `useState`
 * @return {function}                Function that calls both setter arguments
 */
const getSetUser = (setUserid, setPreferences, setAnalytics) => ({ userid, preferences, analytics }) => {
    setUserid(userid);
    setPreferences(preferences);
    setAnalytics(analytics);
};
/**
 * Hook that returns a shortcut function to `setUserid` and `setPreferences`.
 * Does not persist user data to cookie.
 * @param {string} initialUserId                       Hash from the user's identification string.
 * @param {Object[]} initialPreferences                An array of preferences
 * @param {string}   initialPreferences[preferenceId]  Hash id string for a preference
 * @param {string}   initialPreferences[optinValue]    H, L, N. Currently only using H and N.
 * @return {Object}
 * @return {string} .userid                            Hash from the user's identification string.
 * @return {Object[]} .preferences
 * @return {string}   .preferences[preferenceId]       Hash id string for a preference
 * @return {string}   .preferences[optinValue]         H, L, N. Currently only using H and N.
 * @return {function} .setUserid                       State setter from `useState`
 * @return {function} .setPreferences                  State setter from `useState`
 * @return {function} .setUser                         Shortcut function to call `setUserid` and `setPreferences`
 */
export const useUserState = ({ initialUserId, initialPreferences, initialAnalytics }) => {
    const [userid, setUserid] = useState(initialUserId);
    const [preferences, setPreferences] = useState(initialPreferences);
    const [analytics, setAnalytics] = useState(initialAnalytics);

    return {
        userid,
        preferences,
        setUserid,
        setPreferences,
        setUser: getSetUser(setUserid, setPreferences, setAnalytics),
        analytics,
        setAnalytics,
    };
};

/**
 * Hook that returns a function that will update the optin value of a single
 * user preference.
 * @param {function} setUser            Shortcut to set state with `setUserId` and `setPreferences`.
 * @param {function} setNeedsRemoteSynced  State setter for `needsRemoteSynced`, from `useState`.
 * @return {function}                   A function to update a single user preference.
 */
export const useSetUserPreference = ({ setUser, setNeedsRemoteSynced }) => {
    /**
     * Updates a single user preference
     * @param {string} userid                       Hash from the user's identification string.
     * @param {Object[]} preferences                An array of preferences
     * @param {string}   preferences[preferenceId]  Hash id string for a preference
     * @param {string}   preferences[optinValue]    H, L, N. Currently only using H and N.
     * @param {newPreferenceId}                     The id of the preference being udpated.
     * @param {newOptinValue}                       The new optin value for the preference being updated.
     * @return {boolean}                            True if the preference was found and updateable, false if not.
     */
    function setUserPreference(userid, preferences, newPreferenceId, newOptinValue, analytics) {
        const findById = ({ preferenceId }) => preferenceId === newPreferenceId;
        const preference = preferences.find(findById);

        if (preference) {
            const index = preferences.findIndex(findById);
            const newPreference = { ...preference, optinValue: newOptinValue };
            const newPreferences = [...preferences];
            newPreferences[index] = newPreference;
            setUser({ userid, preferences: newPreferences, analytics });
            setNeedsRemoteSynced(true);
            return true;
        }

        // @TODO do some low level logging for an unfound user preference.
        return false;
    }

    return setUserPreference;
};

/**
 * Hook that returns a function to Persist the user data to a cookie and also
 * updates the current state via `setUser`.
 * @param {function} setUser        From return payload of `useUserState`.
 * @param {function} setUserCookie  First index from return of React Cookie `useCookies`.
 * @return {function}               Function that persists a user to state and browser cookie.
 */
export const usePersistUser = ({ setUser, setUserCookie }) => {
    /**
     * Shortcut that calls `setUser` and `setUserCookie`.
     * @param {string} userid                       Hash from the user's identification string.
     * @param {Object[]} preferences                An array of preferences
     * @param {string}   preferences[preferenceId]  Hash id string for a preference
     * @param {string}   preferences[optinValue]    H, L, N. Currently only using H and N.
     */
    function persistUser({ userid, preferences, analytics }) {
        const data = { userid, preferences, analytics };
        setUser(data);
        setUserCookie(
            'user',
            data,
            {
                path: '/',
                maxAge: USER_MAX_AGE,
            },
        );
    }

    return persistUser;
};

/**
 * Returns a function that makes a PUT request. With await, returns an `Error`, `true` or `undefined`.
 * Without it returnsa Promise that will resolve to `Error`, `true` or `undefined`.
 * @param {function} persistUser        Return value of `usePersistUser`.
 * @param {function} setNeedsRemoteSynced  Index 1 of `setState`, ex: `[,setNeedsRemoteSynced] = useState()`.
 * @return {function}                   An async function described below.
 */
export const useUpdatePreferences = ({ persistUser, setNeedsRemoteSynced }) => {
    /**
     * async function that makes a remote call to rufus API to update a user's preferences.
     * @param {string}   userid                     Hash from the user's identification string.
     * @param {Object[]} preferences                An array of preferences
     * @param {string}   preferences[preferenceId]  Hash id string for a preference
     * @param {string}   preferences[optinValue]    H, L, N. Currently only using H and N.
     * @return {(Error|Object)}
     */
    async function updatePreferences({ userid, preferences, analytics, majorSource, minorSource }) {
        return axios.put(
            `${API_ROOT}/preference/`,
            {
                userId: userid,
                preferences: preferences.map(({ preferenceId, optinValue }) => ({ preferenceId, optinValue })),
                majorSource,
                minorSource,
                ...analytics,
            },
        ).then(res => {
            // the request was successful and updated on the remote end. update state and
            // persist user data to a cookie, excluding preferences that have been unsubscribed.
            persistUser({ userid, preferences: preferences.filter(({ optinValue }) => optinValue !== 'N'), analytics });
            // since local and remote data is in sync, the state's no longer unsynced.
            setNeedsRemoteSynced(false);
            return res.data;
        }).catch(error => error);
    }

    return updatePreferences;
};

export default {
    useUserState,
    useSetUserPreference,
    usePersistUser,
    useUpdatePreferences,
};
