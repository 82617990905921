import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
    SmallButton,
    PrimaryButton,
    ErrorButton,
    LinkButton,
} from './Button.styles';

const ButtonMap = {
    'sign-in': SmallButton,
    'error-page': ErrorButton,
    link: LinkButton,
};
const Button = ({ type, text, ...buttonProps }) => {
    const ButtonType = ButtonMap[type] || PrimaryButton;

    return (
        <Fragment>
            <ButtonType {...buttonProps}>
                <FormattedMessage id={text} defaultMessage={text} />
            </ButtonType>
        </Fragment>
    );
};

Button.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string.isRequired,
};

Button.defaultProps = {
    type: 'other',
};

export default Button;
