import styled from 'styled-components';

import { colors, AvenirFont, screen } from '../../common/styles/helpers';

const BaseButton = styled.button`
    ${AvenirFont.DEMI}
    height: 2.8125rem;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: ${colors.BLUE};
    color: ${colors.WHITE};
    border-radius: 0.3125rem;
    font-size: 1rem;
    letter-spacing: 0.0175rem;
    opactiy: 1;
    margin: 0.3125rem;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    &:focus {
        background-color: ${colors.DARK_BLUE};
        outline: 0;
    }
`;

const SmallButton = styled(BaseButton)`
    width: 6.25rem;

    @media (max-width: ${screen.MOBILE}) {
        width: 16.25rem;
    }
`;

const PrimaryButton = styled(BaseButton)`
    width: 12.5rem;

    @media (max-width: ${screen.MOBILE}) {
        width: 16.25rem;
    }
`;

const LinkButton = styled(PrimaryButton)`
    background-color: ${colors.TRANSPARENT};
    color: ${colors.BLUE};
    &:focus {
        background-color: ${colors.TRANSPARENT};
    }
`;

const ErrorButton = styled(PrimaryButton)`
    display: block;
    margin: 1rem auto;
`;

export {
    SmallButton, PrimaryButton, ErrorButton, LinkButton,
};
