import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

// Import other locales we support here
import { addAppLocaleData } from '../../translations/SupportedLocaleData';
import messagesEnUS from '../../translations/en-US.json';
import messagesEnGB from '../../translations/en-GB.json';

import { DEFAULT_LOCALE } from '../../common/Config';

// Add supported local message bundles here...
const messages = {
    'en-US': messagesEnUS,
    'en-GB': messagesEnGB,
};

/**
 * `react-intl` The translation wrapper component that gives all its children access to translate functionality
 * @param {string} locale Language choice
 */
const IntlWrapper = ({ children, locale }) => {
    // Add all the languages we support here...
    addAppLocaleData();

    // default to en-US if locale is not available
    const availableLocale = messages[locale] ? locale : DEFAULT_LOCALE;

    useEffect(() => {
        document.title = messages[availableLocale]['app.title'];
    });

    return (
        <IntlProvider locale={availableLocale} messages={messages[availableLocale]}>
            {children}
        </IntlProvider>
    );
};

IntlWrapper.defaultProps = {
    locale: '',
};

IntlWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    locale: PropTypes.string,
};
export default IntlWrapper;
