import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    LinksContainer,
    FooterLink,
    ConditionalText,
    SlidingDiv,
    FooterText,
    PageFooter,
} from './Layout.styles';
import { useAppContext } from '../../datastore/AppContext';

const Links = {
    TermOfUse: 'http://www.hearst.com/newsroom/us-magazines-terms-of-use',
    PrivacyNotice: 'https://subscribe.hearstmags.com/circulation/shared/privacy.html',
    RemoveFromMail: 'https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&su=Remove+From+Postal+Marketing+List&to=hearstcustserv@cdsfulfillment.com',
};

const BaseLinks = () => (
    <Fragment>
        <FooterLink href={Links.TermOfUse}>
            Terms of Use
        </FooterLink>
        <FooterText>|</FooterText>
        <FooterLink href={Links.PrivacyNotice}>
            Privacy Notice
        </FooterLink>
    </Fragment>
);

const Footer = ({ location }) => {
    const {
        user,
        needsRemoteSynced,
    } = useAppContext();

    /**
     * hasNoSubscriptions will be true if the use is not synced w/ CDS, and either has
     * no preferences or has all preferences set to N
     */
    const hasNoSubscriptions = !needsRemoteSynced
        && (!user.preferences || !user.preferences.length
        || user.preferences.every(({ optinValue }) => optinValue === 'N'));

    /**
     * hideUnsubscribeAllLink will be true if the user has no subscriptions, or the user is on
     * the root page or unsubscribe, or there is no user or a user w/o an id
     */
    const hideUnsubscribeAllLink = location.pathname === '/'
        || location.pathname === '/unsubscribe' || !user || !user.userid
        || hasNoSubscriptions;

    return (
        <PageFooter>
            <LinksContainer>
                <SlidingDiv>
                    <BaseLinks />
                </SlidingDiv>
                <SlidingDiv>
                    <ConditionalText>|</ConditionalText>
                    <FooterLink href={Links.RemoveFromMail}>
                        Remove me from Postal Mail
                    </FooterLink>
                    {!hideUnsubscribeAllLink
                        && (
                        <>
                            <FooterText>|</FooterText>
                            <Link
                              to="/unsubscribe-all"
                              style={{ textDecoration: 'none' }}
                            >
                                <FooterText>Unsubscribe All</FooterText>
                            </Link>
                        </>
)
                    }
                </SlidingDiv>
            </LinksContainer>
        </PageFooter>
    );
};

Footer.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export { Links };
export default withRouter(Footer);
