/**
 * Global config object. Import it in your module instead of accessing stuff there.
 */
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const CDS_MAJORSOURCE_PRF = 'PRF';
export const CDS_MINORSOURCE_UNDEF = 'UNDEF';
export const CDS_MAJORSOURCE_UNSUB = 'UNSUB';
export const DEFAULT_LOCALE = 'en-US';
export const RECAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
// @TODO add this to @ahab and env files. be sure to cast to number.
export const USER_MAX_AGE = Number.isNaN(Number(process.env.USER_MAX_AGE)) ? 3600 : Number(process.env.USER_MAX_AGE);

// create ENV getter and setter for testing
let ENV = process.env.REACT_APP_ENV;

export const setEnv = value => {
    ENV = value;
};

export const getEnv = () => ENV;

export default {
    API_ROOT,
    CDS_MAJORSOURCE_PRF,
    CDS_MINORSOURCE_UNDEF,
    CDS_MAJORSOURCE_UNSUB,
    DEFAULT_LOCALE,
    ENV,
    RECAPTCHA_SITEKEY,
    SENTRY_DSN,
    USER_MAX_AGE,
};
