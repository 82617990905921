const colors = {
    BLUE: '#245f9e',
    RED: '#d50000',
    DARK_BLUE: '#134376',
    WHITE: '#ffffff',
    ORANGE: '#e04b20',
    DARK_ORANGE: '#ad1800',
    LIGHT_GRAY: '#dadce0',
    LIGHTER_GRAY: '#ededed',
    DARK_GRAY: '#4a4a4a',
    DARKER_GRAY: '#6d7278',
    TRANSPARENT: '#00000000',
};

const fonts = {
    AVENIR: '"Avenir Next W05"',
    ARIAL: 'Arial',
};

const AvenirFont = {
    REGULAR: `
        font-family: ${fonts.AVENIR};
        font-weight: 400;
    `,
    MEDIUM: `
        font-family: ${fonts.AVENIR};
        font-weight: 500;
    `,
    DEMI: `
        font-family: ${fonts.AVENIR};
        font-weight: 600;
    `,
};

const screen = {
    MOBILE: '64rem',
};

export {
    colors,
    fonts,
    AvenirFont,
    screen,
};
