import styled from 'styled-components';
import { screen } from '../../common/styles/helpers';

const StyledReCaptcha = styled.div`
    width: 19rem;
    margin: 32px auto;

    @media (max-width: ${screen.MOBILE}) {
        transform: scale(0.85);
    }
`;

export default StyledReCaptcha;
