import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    StyledTitle,
    StyledSubHeader,
} from '../../common/styles/global';
import FormComponent from '../Form/Form.container';

/**
 * Main wrapper component for index page/route.
 */
const IndexComponent = () => (
    <>
        <StyledTitle>
            <FormattedMessage
              id="page.index.title"
              defaultMessage="Welcome to the Hearst Magazines Email Preference Center"
            />
        </StyledTitle>
        <StyledSubHeader>
            <FormattedMessage
              id="page.index.subheader"
              defaultMessage="Please enter your email address below to manage your preferences."
            />
        </StyledSubHeader>
        <FormComponent />
    </>
);

export default IndexComponent;
