import styled from 'styled-components';

import { screen } from '../../common/styles/helpers';

export const StyledForm = styled.form`
    margin: 1rem 0;
    text-align: center;
    @media (max-width: ${screen.MOBILE}) {
        display:flex;
        flex-direction: column;
        align-items: center;
    }
`;

export default { StyledForm };
