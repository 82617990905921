import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Reaptcha from '@panalbish/reaptcha-enterprise';
import * as Sentry from '@sentry/browser';
import StyledReCaptcha from './Recaptcha.styles';

import { RECAPTCHA_SITEKEY, API_ROOT } from '../../common/Config';

/**
 * reCAPTCHA v2 component. Used to verify that a user is not a bot
 * @param {Function} verifiedCallback The function used to pass the reCAPTCHA verified state
 * to the parent component
 */
const ReCaptchaComponent = ({ verifiedCallback }) => {
    const [reCaptchaToken, setReCaptchaToken] = useState('');
    const [reCaptchaLoaded, setReCaptchaLoaded] = useState('');
    const [verification, setVerification] = useState(false);
    const recaptchaRef = useRef();
    /**
     * Sets aria attributes for textarea element inserted by google
     */
    const setAriaAttributes = () => {
        const responseTextArea = document.getElementById('g-recaptcha-response');

        if (responseTextArea) {
            const attributeObj = {
                'aria-hidden': 'true',
                'aria-label': 'do not use',
                'aria-readonly': 'true',
            };

            Object.keys(attributeObj).forEach(key => {
                responseTextArea.setAttribute(key, attributeObj[key]);
            });
        }
    };

    /**
     * Verifies that the token returned is valid
     * @param {string} token The token returned by google
     */
    const getReCaptchaVerification = async token => {
        try {
            const response = await axios.get(`${API_ROOT}/recaptcha/${token}/`);
            return response;
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    /**
     * Sets the reCaptchaLoaded state to true once reCAPTCHA has successfully loaded
     */
    const loadRecaptcha = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.renderExplicitly();
            setReCaptchaLoaded(true);
        }
    };

    /**
     * Retrieves a user token from google, then verifies that token using the
     * `GET /api/recaptcha/{token}` route in the rufus api
     * @param {string} responseToken The token returned by google
     */
    const reCaptchaVerification = async responseToken => {
        setReCaptchaToken(responseToken);
        const response = await getReCaptchaVerification(responseToken);

        if (response && response.data && response.data.code === 200) {
            setVerification(true);
        }
    };

    useEffect(() => {
        setAriaAttributes();

        if (reCaptchaLoaded && reCaptchaToken) {
            verifiedCallback(verification);
        }
    });

    return (
        <StyledReCaptcha>
            <Reaptcha
              ref={recaptchaRef}
              sitekey={RECAPTCHA_SITEKEY}
              onVerify={reCaptchaVerification}
              onLoad={loadRecaptcha}
              explicit
              enterprise
            />
        </StyledReCaptcha>
    );
};

ReCaptchaComponent.propTypes = {
    verifiedCallback: PropTypes.func.isRequired,
};

export default ReCaptchaComponent;
