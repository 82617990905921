import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import checkIcon from '../../assets/images/check-circle.svg';
import Button from '../Button/Button.component';

import { StyledButtonGroup } from '../Unsubscribe/UnsubscribePage.styles';
import { StyledHeadingIcon, StyledTitle, StyledSubHeader } from '../../common/styles/global';

const UnsubscribeComplete = ({ subscriptionName, registerUserAuth, history }) => {
    const onManageAllClick = () => {
        registerUserAuth();
        history.push('/subscriptions');
    };
    return (
        <>
            <StyledHeadingIcon src={checkIcon} alt="unsubscribe complete checkmark" />
            <StyledTitle>
                <FormattedMessage
                  id="page.unsubscribe.completed.success"
                  defaultMessage={`You’ve successfully unsubscribed from the ${subscriptionName}.`}
                  values={{ subscriptionName }}
                />
            </StyledTitle>
            <StyledSubHeader>
                <FormattedMessage
                  default="Manage your other Hearst magazines email preferences by clicking manage all below."
                  id="page.unsubscribe.completed.manageall-text"
                />
            </StyledSubHeader>
            <StyledButtonGroup>
                <Button onClick={onManageAllClick} text="page.unsubscribe.button.manageall" />
            </StyledButtonGroup>
        </>
    );
};

UnsubscribeComplete.propTypes = {
    subscriptionName: PropTypes.string.isRequired,
    registerUserAuth: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(UnsubscribeComplete);
