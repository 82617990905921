import styled from 'styled-components';

import { colors, AvenirFont, screen } from '../../common/styles/helpers';

const StyledErrorContainer = styled.div`
    margin-left: 0.5rem;
    width: 100%;
    @media (max-width: ${screen.MOBILE}) {
        width: 16.25rem;
    }
`;

const StyledError = styled.p`
    ${AvenirFont.REGULAR};
    font-size: 0.75rem;
    letter-spacing: 0.0125rem;
    color: ${colors.RED};
    text-align: left;
    margin-top: 0;
`;

export { StyledErrorContainer, StyledError };
