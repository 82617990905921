import styled from 'styled-components';

import Button from '../Button/Button.component';
import { colors, AvenirFont, screen } from '../../common/styles/helpers';
import checked from '../../assets/images/checked.svg';
import unchecked from '../../assets/images/unchecked.svg';

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
`;

export const List = styled.ul`
    ${AvenirFont.MEDIUM}
    list-style: none;
    padding: 0;
    color: ${colors.DARK_GRAY};
    margin-top: 2.75rem;
`;

export const ListItem = styled.li`
    line-height: 1.1875;
    margin-bottom: 2rem;

    label {
        display: inline-block;
        cursor: pointer;
        padding-bottom: 0.3rem;
        &::before {
            content: '';
            background-image: url(${checked}), url(${unchecked});
            background-repeat: no-repeat, no-repeat;
            background-position: top left, top left;
            background-size: ${({ active }) => (active ? 'cover, 0' : '0, cover')};
            width: 1.1875rem;
            height: 1.1875rem;
            display: inline-block;
            vertical-align: top;
            margin: 0.2rem 0.75rem 0 0;
        }
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 500;
        margin: 0;
        display: inline-block;
    }

    p {
        font-size: 1rem;
        margin: 0 0 0 1.9375rem
    }

    input[type="checkbox"] {
        display: none;
    }
`;

export const Disclaimer = styled.p`
    ${AvenirFont.DEMI}
    font-size: 0.875rem;
    text-align: center;
    margin: 1.5625rem 0;
    position: absolute;
    bottom: 0;
    @media (max-width: ${screen.MOBILE}) {
        width: 19rem;
    }

`;

export const UpdateButton = styled(Button)`
    margin: 0 auto;
`;
