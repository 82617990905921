import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '../Button/Button.component';
import { StyledButtonGroup } from './UnsubscribePage.styles';
import { StyledHeadingIcon, StyledTitle } from '../../common/styles/global';
import questionIcon from '../../assets/images/question-circle.svg';

const UnsubscribeConfirm = ({
    subscriptionName, onConfirmClick,
}) => (
    <>
        <StyledHeadingIcon src={questionIcon} alt="unsubscribe confirmation question mark" />
        <StyledTitle>
            <FormattedMessage
              id="page.unsubscribe.message"
              defaultMessage={`Are you sure you want to unsubscribe from the ${subscriptionName}?`}
              values={{ subscriptionName }}
            />
        </StyledTitle>
        <StyledButtonGroup>
            <Button
              onClick={onConfirmClick}
              text="page.unsubscribe.button.confirm"
            />
            <Link to="/">
                <Button type="link" text="page.unsubscribe.button.cancel" />
            </Link>
        </StyledButtonGroup>
    </>
);

UnsubscribeConfirm.propTypes = {
    subscriptionName: PropTypes.string.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
};

export default UnsubscribeConfirm;
