import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import crypto from 'crypto-browserify';
import PropTypes from 'prop-types';
import axios from 'axios';

import { useAppContext } from '../../datastore/AppContext';
import {
    API_ROOT,
    CDS_MAJORSOURCE_UNSUB as majorSource,
    CDS_MINORSOURCE_UNDEF as minorSource,
} from '../../common/Config';
import UnsubscribeComplete from '../UnsubscribeComplete/UnsubscribeComplete.component';
import UnsubscribeConfirm from './UnsubscribeConfirm.component';

import { StyledHeadingIcon } from '../../common/styles/global';
import loadingIcon from '../../assets/images/loader.gif';

/**
 * Retrieve preference object using a preference Id
 * @param {Object[]} preferences    An array of preferences
 * @param {string} preferenceId     A id of preference
 * @return {Object[]}               An object of preference
 */
export const getPreferenceById = (preferences, preferenceId) => (
    preferences.find(preference => preference.pref_id === preferenceId || preference.preferenceId === preferenceId)
);

/**
 * Converts encoded characters in an auth id and then remoes curly braces.
 */
export const sanitizeAuthId = authId => decodeURIComponent(authId).replace(/{|}/g, '');

/**
 * Takes a url search string and builds an object from it.
 *
 * @param {string} searchString  The value of url query string, or a similarly formed string (key value pairs
 *                               separated by `&`, keys and values separatd by `=`, with or without a leading `?`)
 *                               ex `?foo=bar&baz=` will return `{ foo: 'bar', baz: '' }`
 * @return {object}
 */
export const parseQueryParams = searchString => searchString.replace(/^\?/, '').split('&').reduce((params, keyVal) => {
    const [key, val] = keyVal.split('=');
    params[key] = val; // eslint-disable-line no-param-reassign
    return params;
}, {});

const useSubscriptionName = (authId, userId, analyticsSource) => {
    const [subscriptionName, setsubscriptionName] = useState('');
    const [userPreferences, setUserPreferences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [analytics, setAnalytics] = useState(analyticsSource);

    useEffect(() => {
        async function fetchData() {
            try {
                // make sure both authId and userId exist
                if (!authId || !userId) throw new Error();
                const response = await axios.get(`${API_ROOT}/preference/${userId}/`);
                const result = await response.data;
                // two sets of possible results could be returned
                // 1.userId 04c46cac877e23895670baa98ecbd162 {
                //   "preferenceId": "200666AB-1510-4749-B98D-39B86E24ED4C",
                //   "optinValue": "N",
                //   "magazine": "Hearst Direct",
                //   "pc_short_desc": "Hearst Direct Special Offers",
                //   "pc_long_desc": "Enhance your life with health, fitness and wellness products from Hearst Direct."
                //   }
                // 2. userId f767d1cb31a92d5edb149f132e66dd02 {
                //    "[{\"pref_id\": \"E6DF3A84-E8C6-47DC-B9EC-EDA8E905F8E2\"]"
                //   }

                let allUserPreferences = result.data.preferences;
                if (typeof allUserPreferences === 'string') allUserPreferences = JSON.parse(allUserPreferences);

                const preference = getPreferenceById(allUserPreferences, authId);
                if (preference) {
                    setsubscriptionName(
                        preference.pc_short_desc || preference.magazine,
                    );
                    setUserPreferences(allUserPreferences);
                    setAnalytics(() => analytics);
                }
            } catch (error) {
                // default subscription name is empty.
                // If we reach here, it will take the user to the error page
                // TODO: we should take them to a preferences do not exist for this user page
            }
            setLoading(false);
        }
        fetchData();
    }, [authId, userId, analytics]);
    return { subscriptionName, userPreferences, loading };
};

const UnsubscribeComponent = ({ location, history }) => {
    const [hasUnsubscribed, setUnsubscribed] = useState(false);
    const { persistUser, updatePreferences } = useAppContext();
    const {
        authId,
        ds,
        si,
        user,
        huser,
    } = parseQueryParams(location.search);
    const sanitizedAuthId = authId ? sanitizeAuthId(authId) : authId;
    // user or huser will be passed in. huser is the already encrypted md5 string of the email
    // where user is just the email. If user email is passed in, encrypt it to md5
    const userId = user && !huser ? crypto.createHash('md5').update(user).digest('hex') : huser;

    const analytics = {
        analyticString1: ds,
        analyticString2: si,
    };

    const {
        subscriptionName,
        userPreferences,
        loading: subscriptionNameLoading,
    } = useSubscriptionName(sanitizedAuthId, userId, analytics);

    if (subscriptionNameLoading) {
        return <StyledHeadingIcon src={loadingIcon} alt="subscription loading" />;
    }

    if (!subscriptionName) {
        return <Redirect to="/error" />;
    }

    const registerUserAuthContext = () => {
        // get the preference being unsubscribed from and set the optinValue to 'N'
        const selectedPreference = getPreferenceById(userPreferences, sanitizedAuthId);
        selectedPreference.optinValue = 'N';
        persistUser({
            userid: userId,
            // filter out unsubscribe preferences for display on rerender of the listing page.
            preferences: userPreferences.filter(({ optinValue }) => optinValue !== 'N'),
            analytics,
        });
    };

    if (hasUnsubscribed) {
        return (
            <UnsubscribeComplete
              subscriptionName={subscriptionName}
              registerUserAuth={registerUserAuthContext}
            />
        );
    }

    const onConfirmClick = async () => {
        const response = await updatePreferences({
            userid: userId,
            preferences: [{
                preferenceId: sanitizedAuthId,
                optinValue: 'N',
            }],
            majorSource,
            minorSource,
            analytics,
        });

        if (Object.prototype.isPrototypeOf.call(Error.prototype, response)) {
            history.push('/error');
        } else {
            setUnsubscribed(true);
        }
    };

    return (
        <UnsubscribeConfirm
          subscriptionName={subscriptionName}
          onConfirmClick={onConfirmClick}
        />
    );
};

UnsubscribeComponent.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default UnsubscribeComponent;
