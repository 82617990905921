import React from 'react';

import { useAppContext } from '../../datastore/AppContext';
import SubscriptionItem from './SubscriptionItem.component';
import { List } from './Subscriptions.styles';

/**
 * Remaps an array of preferences with CDS underscore naming convention to js camelCase.
 * Turn linting off here if it complains.
 * @param {Object[]} preferences
 * @param {string}   preferences.preferenceId
 * @param {string}   preferences.magazine
 * @param {string}   preferences.pc_long_desc
 * @param {string}   preferences.pc_long_desc
 * @param {string}   preferences.active
 */
/* eslint camelcase: "off" */
export const remapCdsPreferences = (preferences = []) => preferences.reduce(
    (remapped, { preferenceId, magazine, pc_short_desc, pc_long_desc, optinValue }) => {
        remapped.push({
            preferenceId,
            title: pc_short_desc || magazine,
            description: pc_long_desc,
            active: (optinValue !== 'N'),
        });

        return remapped;
    }, [],
);

/**
 * sort the preference list by title property alphabetically
 * @param {Object[]} preferences
 */
export const sortPreferences = preferences => {
    if (!preferences || !preferences.length) return [];

    return preferences.sort((firstPref, secondPref) => (firstPref.title > secondPref.title ? 1 : -1));
};

/**
 * Unordered list component that stores the preference list items. Relies on AppContext.
 */
const SubscriptionsList = () => {
    const { user: { userid, preferences } } = useAppContext();

    return (
        <List>
            {sortPreferences(remapCdsPreferences(preferences)).map(preference => (
                <SubscriptionItem key={preference.preferenceId} {...{ userid, ...preference }} />
            ))}
        </List>
    );
};

export default SubscriptionsList;
