import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';

import {
    CDS_MAJORSOURCE_PRF as majorSource,
    CDS_MINORSOURCE_UNDEF as minorSource,
} from '../../common/Config';
import { useAppContext } from '../../datastore/AppContext';
import SubscriptionsList from './SubscriptionsList.component';
import { StyledTitle, StyledSubHeader } from '../../common/styles/global';
import { Disclaimer, ListContainer, UpdateButton } from './Subscriptions.styles';

/**
 * Main wrapper component for the subscription list page/route.
 * @param {Object} history  The history api used by react router.
 */
const SubscriptionsComponent = ({ history }) => {
    const [prefUpdated, setPrefUpdated] = useState(false);
    const {
        user,
        needsRemoteSynced,
        updatePreferences,
    } = useAppContext();

    const updateOrError = async () => {
        const response = await updatePreferences({
            ...user,
            majorSource,
            minorSource,
        });

        setPrefUpdated(true);

        if (Object.prototype.isPrototypeOf.call(Error.prototype, response)) {
            setPrefUpdated(false);
            history.push('/error');
        } else {
            // send GTM update event
            window.dataLayer.push({
                event: 'update_preference',
                url: window.location.pathname,
            });
        }
    };

    // if there's no user, redirect to root.
    if (!user.userid) {
        return <Redirect to="/" />;
    }

    const hasNoSubscriptions = !needsRemoteSynced
        && (!user.preferences || !user.preferences.length
            || user.preferences.every(({ optinValue }) => optinValue === 'N'));

    const renderContent = () => {
        if (hasNoSubscriptions) {
            return (
                <StyledSubHeader>
                    <FormattedMessage
                      id="page.subscription-list.nosubscription"
                      defaultMessage="This email address is not subscribed to any Hearst magazine emails."
                    />
                </StyledSubHeader>
            );
        }

        return (
            <>
                <StyledSubHeader>
                    <FormattedMessage
                      id="page.subscription-list.subheader"
                      defaultMessage="We don't want to see you go, but we do want to make sure you're only
                      receiving emails you'll enjoy. To update your email preferences, uncheck the
                      box next to each email you no longer want to receive."
                    />
                </StyledSubHeader>
                <SubscriptionsList />
                {needsRemoteSynced
                    && (
                        <UpdateButton
                          onClick={updateOrError}
                          text="page.subscription-list.button.update"
                        />
                    )
                }
            </>
        );
    };

    return (
        <>
            <ListContainer>
                <StyledTitle>
                    <FormattedMessage
                      id="page.subscription-list.header"
                      defaultMessage="Email Preference Center"
                    />
                </StyledTitle>
                {renderContent()}
            </ListContainer>
            <Disclaimer>
                {prefUpdated ? (
                    <FormattedMessage
                      id="page.subscription-list.updated.disclaimer"
                      defaultMessage="Your preferences have been updated, please allow up to 48 hours
                for your changes to take effect."
                    />
            ) : (
                <FormattedMessage
                  id="page.subscription-list.disclaimer"
                  defaultMessage="Please allow up to 48 hours for your changes to take effect."
                />
            )}
            </Disclaimer>
        </>

    );
};

SubscriptionsComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(SubscriptionsComponent);
