import styled from 'styled-components';

import { colors, AvenirFont, screen } from '../../common/styles/helpers';

const Logo = styled.img`
    margin: 0 auto;
    padding: 2rem;
    height: 2.3125rem;
    width: auto;

    @media (max-width: ${screen.MOBILE}) {
        display: block;
        padding: 2rem auto;
    }
`;

const WhiteLogo = styled(Logo)`
    position: absolute;
    opacity: 1;

    @media (max-width: ${screen.MOBILE}) {
        margin: auto;
        left: 0;
        right: 0;
        padding-top: 1.5rem;
        text-align: center;
    }
`;

const LinksContainer = styled.div`
    margin: auto;
`;

const SlidingDiv = styled.div`
    display: inline-block;

    @media (max-width: 26.25rem) {
        display: block;
        margin: 0;
        text-align: center;
    }
`;

const FooterLink = styled.a`
    ${AvenirFont.MEDIUM}
    font-size: 0.75rem;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.01125rem;
    text-align: center;
    color: ${colors.DARK_GRAY};
    text-decoration: none;
    margin: auto;
    justify-content: space-evenly;
    padding: 0 0.375rem;
`;

const FooterText = styled.span`
    ${AvenirFont.MEDIUM}
    font-size: 0.75rem;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.01125rem;
    text-align: center;
    color: ${colors.DARK_GRAY};
    text-decoration: none;
    margin: auto;
    justify-content: space-evenly;
    padding: 0 0.375rem;
`;

const ConditionalText = styled(FooterText)`
    @media (max-width: 26.25rem) {
        display: none;
    }
`;

const FooterHeight = '4rem';

const PageHeader = styled.div`
    height:10vh;
    position: absolute;
    @media (max-width: ${screen.MOBILE}) {
        position: relative;
    }
`;

const PageContent = styled.div`
    min-height: calc(100vh - ${FooterHeight});
    box-sizing:border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin: 0 auto;
    padding: 2rem 2rem 0 2rem;
    max-width: 53rem;
    @media (max-width: ${screen.MOBILE}) {
        min-height: calc(90vh - ${FooterHeight});
    }
`;
const PageFooter = styled.footer`
    display: flex;
    background-color: ${colors.LIGHTER_GRAY};
    padding: 1.25rem;
    height: 4rem;
    padding: 0 0.6875rem;
`;
export {
    Logo,
    WhiteLogo,
    LinksContainer,
    FooterLink,
    ConditionalText,
    SlidingDiv,
    FooterText,
    PageHeader,
    PageContent,
    PageFooter,
};
