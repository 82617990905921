import styled, { createGlobalStyle } from 'styled-components';

import { colors, fonts, AvenirFont, screen } from './helpers';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ${fonts.AVENIR};
    src: url("fonts/5d53f653-e4fa-44ce-9592-5d25067889ac.eot?#iefix");
    src: url("fonts/5d53f653-e4fa-44ce-9592-5d25067889ac.eot?#iefix") format("eot"),
      url("fonts/f64c3634-1027-4aec-8bd4-d96f2444f559.woff2") format("woff2"),
      url("fonts/91f23395-35b5-470e-9680-45d860e84f5b.woff") format("woff"),
      url("fonts/39581d84-4e26-4f6b-a1b3-de93f8d78fca.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: ${fonts.AVENIR};
    src: url("fonts/d44e7fd4-c56f-4173-a424-053487ceed84.eot?#iefix");
    src: url("fonts/d44e7fd4-c56f-4173-a424-053487ceed84.eot?#iefix") format("eot"),
      url("fonts/a1049d00-54ad-4589-95b8-d353f7ab52f0.woff2") format("woff2"),
      url("fonts/13faf0ae-dcab-4d1c-9c08-f9ca339b6023.woff") format("woff"),
      url("fonts/297843ea-cf99-42a0-a97f-99f552bcc76b.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: ${fonts.AVENIR};
    src: url("fonts/95da0114-2da9-4b03-9f4d-75864e43998e.eot?#iefix");
    src: url("fonts/95da0114-2da9-4b03-9f4d-75864e43998e.eot?#iefix") format("eot"),
      url("fonts/8c6d7851-9978-4520-8c8f-dc1b38b36eb6.woff2") format("woff2"),
      url("fonts/73fd8503-a80c-4965-97ae-a1178b2caad0.woff") format("woff"),
      url("fonts/4dd3ecca-15ff-4b92-9355-6597b69e0522.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  body {
    background: ${colors.WHITE};
    font-family: ${fonts.ARIAL};
    margin: 0;
    padding: 0;
  }
`;

const StyledHeadingIcon = styled.img`
    display: block;
    width: 4.5rem;
    height: 4.5rem;
    margin: 1rem auto;
    @media (max-width: ${screen.MOBILE}) {
      width: 3.5rem;
      height: 3.5rem;    
    }
`;

const StyledTitle = styled.h1`
    ${AvenirFont.MEDIUM}
    color: ${colors.DARK_GRAY};
    font-size: 2.62rem;
    text-align: center;
    line-height: 1.25;
    letter-spacing: 0.4px;
    margin: 1rem 0;
    @media (max-width: ${screen.MOBILE}) {
        font-size: 1.5rem;
    }
`;

const StyledSubHeader = styled.h2`
    ${AvenirFont.DEMI}
    color: ${colors.DARKER_GRAY};
    font-size: 1rem;
    text-align: center;
    margin: 1rem 0;
`;

export {
    GlobalStyle,
    colors,
    StyledHeadingIcon,
    StyledTitle,
    StyledSubHeader,
};
