import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Logo, WhiteLogo, PageHeader } from './Layout.styles';
import logo from '../../assets/images/hearst-logo.svg';
import whiteLogo from '../../assets/images/hearst-logo-white.svg';

const Header = ({ location }) => {
    // TODO: change '/login' below to match the path of the login screen once it has been created
    if (location.pathname === '/login') {
        return <WhiteLogo src={whiteLogo} />;
    }

    return (
        <PageHeader>
            <Link to="/">
                <Logo src={logo} alt="hearst magazines logo" />
            </Link>
        </PageHeader>

    );
};

Header.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(Header);
