import styled from 'styled-components';
import { colors, AvenirFont, screen } from '../../common/styles/helpers';

const StyledInputContainer = styled.div`
    ${AvenirFont.REGULAR}
    position: relative;
    display: inline-block;
    vertical-align:bottom;
    overflow: hidden;
    width:300px;
    margin: 0.3125rem;
    color: ${colors.DARK_GRAY};
    background-color: ${colors.WHITE};
    border: solid 1px ${({ hasError, focused }) => (
        (hasError && colors.RED)
        || (focused ? colors.BLUE : colors.LIGHT_GRAY))};
    border-radius: 5px;
    height: 2.8125rem;
    box-sizing: border-box;
    text-align: left;
    outline: none;
    padding-left: 1rem;

    @media (max-width: ${screen.MOBILE}) {
        width: 16.25rem;
    }
`;

const StyledInputLabel = styled.label`
    position: absolute;
    line-height: 1rem;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transform: translate(0, 1rem) scale(1);
    transform-origin: top left;

    ${({ hasError, focused }) => ((hasError && `color: ${colors.RED};`) || (focused ? `color: ${colors.BLUE};` : ''))}

    ${({ focused, value }) => (focused || value
        ? `
    transform: translate(0, 1.5px) scale(0.65); 
    padding-top:0.2rem; 
    font-weight:bold;
    ` : '')}

`;

const StyledInput = styled.input`
    ${AvenirFont.REGULAR}
    position: absolute;
    border:0;
    background-color: transparent;
    width:100%;
    height:100%;
    font-size:1rem;
    color:#6B6B6B;
    z-index:5;
    margin-top:0.3rem;

    &:focus{
        border:0;
        outline-width: 0;
        outline: none;
    } 
`;

export { StyledInputContainer, StyledInputLabel, StyledInput };
