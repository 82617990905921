import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { StyledErrorContainer, StyledError } from './ErrorMessage.styles';

/**
 * Render red error message with the react-intl text id
 * @param {string} textId translation Id
 * @param {string} defaultMessage fall back message when textId is invalid
 * @param {object} messageParameters(optional) - dynamic variables inside the message. ex. {subscriptionName: ''}
 */
const ErrorMessage = ({ textId, defaultMessage, messageParameters }) => (
    <StyledErrorContainer>
        <StyledError>
            <FormattedMessage id={textId} defaultMessage={defaultMessage} values={messageParameters} />
        </StyledError>
    </StyledErrorContainer>

);

ErrorMessage.defaultProps = {
    defaultMessage: 'Error',
    messageParameters: {},
};

ErrorMessage.propTypes = {
    textId: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
    messageParameters: PropTypes.shape({}),
};

export default ErrorMessage;
