import React, { useState } from 'react';
import PropTypes from 'prop-types';
import crypto from 'crypto-browserify';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useAppContext } from '../../datastore/AppContext';
import { API_ROOT, getEnv } from '../../common/Config';

import Button from '../Button/Button.component';
import Login from '../Login/Login.component';
import ErrorMessage from '../Error/ErrorMessage.component';
import ReCaptchaComponent from '../Recaptcha/Recaptcha.component';

import { StyledForm } from './Form.styles';
/**
 * Validate email
 * @param {string} email the email to validate
 * @return {Boolean} whether the email is valid
 */
export const validateEmail = email => {
    if (!email) return false;
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
    return emailRegex.test(email);
};

/**
 * make network call to rufus API and get the user preferences
 * @param {string} email the user email
 * @return {{hashedEmail:String, preferences:Array}} the md5 encoded email string and the user preferences array
 */
export const getUserPreferencesByEmail = async email => {
    let hashedEmail = '';
    let preferences;
    try {
        hashedEmail = crypto.createHash('md5').update(email.toLowerCase()).digest('hex');
        const res = await axios.get(`${API_ROOT}/preference/${hashedEmail}/`);
        ({ data: { data: { preferences } } } = res);
        if (!preferences) hashedEmail = '';
    } catch (error) {
        // set hashed email to empty string indicate failure
        hashedEmail = '';
    }

    return { hashedEmail, preferences };
};

/**
 * The login page form containing the email input box, recaptcha, and submit button
 */
const FormComponent = ({ history }) => {
    const ENV = getEnv();

    const [errorTextId, setErrorTextId] = useState('');
    const [email, setEmail] = useState('');
    const [isVerified, setIsVerified] = useState(ENV !== 'production');
    const { persistUser, analytics } = useAppContext();

    const onEmailChange = evt => {
        setEmail(evt.target.value.trim());
    };

    const verifiedCallback = bool => {
        setIsVerified(bool);
    };

    const onFormSubmit = async evt => {
        evt.preventDefault();

        // validate email
        if (!validateEmail(email)) {
            setErrorTextId('page.login.invalid.text');
            return;
        }

        // make sure recapcha is checked off
        if (!isVerified) {
            setErrorTextId('page.index.recaptcha.text');
            return;
        }

        // get the user preferences
        const { hashedEmail, preferences } = await getUserPreferencesByEmail(email);

        if (!hashedEmail) {
            setErrorTextId('page.login.not-found.text');
            return;
        }

        // clear out any error message
        if (errorTextId) setErrorTextId('');

        // update the context and cookie
        persistUser({
            userid: hashedEmail,
            // filter for subscribed preferences
            preferences: preferences.filter(preference => preference.optinValue !== 'N'),
            analytics,
        });

        // finally redirect to the subscription list page
        history.push('/subscriptions');
    };

    return (
        <StyledForm onSubmit={onFormSubmit} noValidate>
            <Login
              dataLabel="page.index.sign-in"
              handleChange={onEmailChange}
              value={email}
              hasError={errorTextId !== ''}
            />
            <Button type="sign-in" text="page.index.sign-in" />
            {errorTextId
                && (
                    <ErrorMessage textId={errorTextId} />
                )
            }
            {ENV === 'production'
                && <ReCaptchaComponent verifiedCallback={verifiedCallback} />
            }
        </StyledForm>
    );
};

FormComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(FormComponent);
