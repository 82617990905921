import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    padding: 0 2rem;
`;

export default StyledButtonGroup;
