import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { useAppContext } from '../../datastore/AppContext';
import { StyledHeadingIcon, StyledTitle, StyledSubHeader } from '../../common/styles/global';
import triangle from '../../assets/images/error-triangle.png';
import ErrorButton from '../Button/Button.component';

const ErrorPage = ({ history }) => {
    const { user } = useAppContext();

    const onManageAllClick = () => {
        history.push(user && user.userid ? '/subscriptions' : '');
    };
    return (
        <>
            <StyledHeadingIcon src={triangle} alt="an error occurred triangle" />
            <StyledTitle>
                <FormattedMessage
                  id="page.error.oops.text"
                  default="Oops something went wrong"
                />
            </StyledTitle>
            <StyledSubHeader>
                <FormattedMessage
                  id="page.error.apology.text"
                  default="We apologize for the inconvenience, please click the button below to manage
                    all your Hearst Magazines email preferences."
                />
            </StyledSubHeader>
            <ErrorButton onClick={onManageAllClick} type="error-page" text="page.error.button.text" />
        </>
    );
};

ErrorPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(ErrorPage);
