import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useAppContext } from '../../datastore/AppContext';
import { ListItem } from './Subscriptions.styles';

/**
 * List item w/ checkbox for a single subscription item. Modifies app context.
 * @param {string} userid        User id string, hashed from email
 * @param {string} preferenceId  Hash id string of preference
 * @param {string} title         Title the preference is for
 * @param {string} description   Description of the preference
 * @param {boolean} active       Whether or not the preferences is actie
 */
const SubscriptionItem = ({ userid, preferenceId, title, description, active }) => {
    const { user: { preferences, analytics }, setUserPreference } = useAppContext();

    /**
     * Toggles the state of a single user preference.
     * Checked is `'H'`, unchecked is `'N'`.
     * This only updates context, not cookies or remote data.
     */
    const togglePreference = evt => {
        const { checked } = evt.currentTarget;
        const optinValue = checked ? 'H' : 'N';

        setUserPreference(userid, preferences, preferenceId, optinValue, analytics);
    };

    return (
        <ListItem data-id={preferenceId} active={active}>
            <label htmlFor={`input-${preferenceId}`}>
                <input id={`input-${preferenceId}`} type="checkbox" checked={active} onChange={togglePreference} />
                <h3>{title}</h3>
            </label>
            <p>{description}</p>
        </ListItem>
    );
};

SubscriptionItem.propTypes = {
    userid: PropTypes.string.isRequired,
    preferenceId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
};

export default withRouter(SubscriptionItem);
