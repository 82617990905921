import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import IndexComponent from './components/Index/Index.container';
import { GlobalStyle } from './common/styles/global';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary.component';
import Header from './components/Layout/Header.component';
import Footer from './components/Layout/Footer.component';
import { PageContent } from './components/Layout/Layout.styles';
import ErrorComponent from './components/Error/ErrorPage.container';
import AppStore from './datastore/AppStore';
import SubscriptionsComponent from './components/Subscriptions/SubscriptionsPage.container';
import UnsubscribeComponent from './components/Unsubscribe/UnsubscribePage.container';
import UnsubscribeAllComponent from './components/Unsubscribe/UnsubscribeAll.container';
import IntlWrapper from './components/Wrappers/IntlWrapper';

const RouteMap = [
    { path: '/', component: IndexComponent },
    { path: '/unsubscribe', component: UnsubscribeComponent },
    { path: '/subscriptions', component: SubscriptionsComponent },
    { path: '/error', component: ErrorComponent },
    { path: '/unsubscribe-all', component: UnsubscribeAllComponent },
];

const App = () => {
    const browserLocale = navigator.language;

    const renderRoutes = () => RouteMap.map(({ path, component }) => (
        <Route
          key={path}
          exact
          path={path}
          component={component}
        />
    ));

    return (
        <CookiesProvider>
            <div className="App">
                <IntlWrapper locale={browserLocale}>
                    <>
                        <GlobalStyle />
                        <Router>
                            <AppStore>
                                <>

                                    <Header />
                                    <PageContent>
                                        <ErrorBoundary>
                                            <Switch>
                                                {renderRoutes()}
                                                <Redirect to="error" />
                                            </Switch>
                                        </ErrorBoundary>
                                    </PageContent>
                                    <Footer />
                                </>
                            </AppStore>
                        </Router>
                    </>
                </IntlWrapper>
            </div>
        </CookiesProvider>
    );
};

export default App;
