import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCookies, withCookies } from 'react-cookie';

import AppContext from './AppContext';
import { useUserState, useSetUserPreference, usePersistUser, useUpdatePreferences } from '../common/hooks';

/**
 * Context component that serves as the main store for the application.
 * @param {Object[]} children                An array of React components
 * @param {string} initialUserId             A hashed email/user id
 * @param {Object[]} initialUserPreferences  An array of preferences
 */
function AppStore({ children, initialUserId, initialUserPreferences, initialAnalytics }) {
    // retrieve user data from the cookie, if it's there
    const [{ user }, setUserCookie] = useCookies(['user']);
    // get state values and setters, setting initial state to cookie data or defaults.
    const {
        userid,
        preferences,
        setUserid,
        setPreferences,
        setUser,
        analytics,
        setAnalytics,
    } = useUserState({
        initialUserId: (user ? user.userid : initialUserId),
        initialPreferences: (user ? user.preferences : initialUserPreferences),
        initialAnalytics: (user ? user.analytics : initialAnalytics),
    });
    // `true` when app state data has changed but the remote update hasn't succeeded.
    const [needsRemoteSynced, setNeedsRemoteSynced] = useState(false);
    const setUserPreference = useSetUserPreference({ setUser, setNeedsRemoteSynced });
    const persistUser = usePersistUser({ setUser, setUserCookie });
    const updatePreferences = useUpdatePreferences({ persistUser, setNeedsRemoteSynced });
    const store = useMemo(() => ({
        user: {
            userid,
            preferences,
            analytics,
        },
        needsRemoteSynced,
        persistUser,
        setUser,
        setUserid,
        setUserPreference,
        setPreferences,
        updatePreferences,
        setAnalytics,
    }), [
        userid,
        preferences,
        analytics,
        needsRemoteSynced,
        persistUser,
        setUser,
        setUserid,
        setUserPreference,
        setPreferences,
        updatePreferences,
        setAnalytics,
    ]);

    return (
        <AppContext.Provider value={store}>
            {children}
        </AppContext.Provider>
    );
}

AppStore.defaultProps = {
    initialUserId: null,
    initialUserPreferences: [],
    initialAnalytics: {
        analyticString1: null,
        analyticString2: null,
    },
};

AppStore.propTypes = {
    children: PropTypes.node.isRequired,
    initialUserId: PropTypes.string,
    initialUserPreferences: PropTypes.arrayOf(Object),
    initialAnalytics: PropTypes.shape({
        analyticString1: PropTypes.string,
        analyticString2: PropTypes.string,
    }),
};

export default withCookies(AppStore);
